<template lang="pug">
.account-page.flex.column.gap-20
  .op.flex.align-center.gap-20
    el-button(
      @click="newAccount"
      type="primary", size="large", icon="el-icon-plus") 新增帳號
  .table.hack-table
    el-table(:data="accounts", width="auto")
      el-table-column(label="姓名")
        template(slot-scope="scope")
          span(v-if="scope.row.enabled") {{ scope.row.name }}
          span.text-placeholder(v-else) {{ scope.row.name }}
      el-table-column(label="電子信箱")
        template(slot-scope="scope")
          span(v-if="scope.row.enabled") {{ scope.row.email }}
          span.text-placeholder(v-else) {{ scope.row.email }}
      el-table-column(label="角色")
        template(slot-scope="scope")
          span(:class="{'text-placeholder': !scope.row.enabled}")
            .role(v-if="scope.row.role === 'admin'") 管理員
            .role(v-if="scope.row.role === 'staff'") 個管師
            .role(v-if="scope.row.role === 'advstaff'") 進階個管師
      el-table-column(label="啟用狀態")
        template(slot-scope="scope")
          toggle(
            @input="changeAccountStatus(scope.row, $event)"
            active-text="啟用"
            inactive-text="停用"
            v-model="scope.row.enabled")
      el-table-column(label="雙重驗證")
        template(slot-scope="scope")
          .status.flex.align-center.gap-10
            template(v-if="scope.row['2pa']")
              .icon.enable
                i.el-icon-check
              span 完成驗證
            template(v-else)
              .icon.disable
                i.el-icon-close
              span 尚未驗證
      el-table-column(label="操作")
        template(slot-scope="scope")
          .ops.flex.align-center
            el-button(size="mini", @click="startEdit(scope.row)") 編輯
            template(v-if="scope.row['2pa']")
              el-button(size="mini", @click="deleteAccountSecond(scope.row)") 解除綁定驗證
  account-editor(ref="accountEditor", @finish="finishEdit")
</template>

<script>
import {
  getAccounts,
  setAccountEnable,
  deleteAccountToken,
} from '@/api/management';
import AccountEditor from '@/components/drawers/AccountEditor.vue';

export default {
  components: {
    'account-editor': AccountEditor,
  },
  methods: {
    async loadData() {
      return this.$execWithLoading(async () => {
        const rsp = await getAccounts();
        rsp.sort((a) => (a.enabled ? -1 : 1));
        this.accounts = rsp;
      });
    },
    async changeAccountStatus(account, status) {
      return this.$execWithLoading(async () => {
        await setAccountEnable(account.id, status);
        this.$showSuccess('更新成功');
      }, () => {
        this.$showError('更新失敗，請稍後重試');
        // eslint-disable-next-line no-param-reassign
        account.enabled = !status;
      });
    },
    async deleteAccountSecond(account) {
      return this.$execWithLoading(async () => {
        await deleteAccountToken(account.id);
        this.$showSuccess('已解除綁定');
        // eslint-disable-next-line no-param-reassign
        account['2pa'] = false;
      }, () => {
        this.$showError('更新失敗，請稍後重試');
      });
    },
    newAccount() {
      this.$refs.accountEditor.$emit('show');
    },
    startEdit(account) {
      this.$refs.accountEditor.$emit('show', account);
    },
    finishEdit() {
      this.loadData();
    },
  },
  data() {
    return {
      accounts: [],
    };
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.table {
  .status {
    .icon {
      width: 14px;
      height: 14px;
      flex: 0 0 14px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        zoom: 0.5;
        color: white;
        font-weight: bold;
      }
      &.enable {
        background: $success-color;
      }
      &.disable {
        background: $danger-color;
      }
    }
  }
}
</style>
