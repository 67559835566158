<template lang="pug">
.payslip-page.flex.column.flex-1.gap-20
  .controller.flex.align-center.justify-space-between
    .left.flex.align-center.gap-6.font-size-20
      .year.bold {{ year }}
      .month.bold {{ month }} 月
      el-button(icon="el-icon-arrow-left" circle, @click="prevMonth")
      el-button(icon="el-icon-arrow-right" circle, @click="nextMonth")
    .right
      el-button(type="primary", :disabled="!canBatchSendNotify", @click="sendBatchNotify") 薪資確認通知
  .content.flex-1.flex.column
    el-table(:data="payslips", @expand-change="expandRow")
      el-table-column(width="40px")
        template(slot="header", slot-scope="scope")
          .flex.align-center
            el-checkbox(v-model="allChecked")
        template(slot-scope="scope")
          el-checkbox(v-model="scope.row.checked", :disabled="!scope.row.canNotify")
      el-table-column(type="expand")
        template(slot-scope="scope")
          .expand-content.p-20.flex.gap-20.align-start
            .detail.part.flex.column.gap-10
              .title 結帳收入明細
              .detail-item.flex.column
                .row.flex.align-center.justify-space-between
                  span 自費諮商
                  span NT $ {{ $toCurrencyNumber(scope.row.self_paid_appointment_amount) }}
                .row(v-if="scope.row.range?.selfPaid")
                  .text-clickable(@click="goAccountingPage(scope.row, 'selfPaid', scope.row.range.selfPaid)"
                    ) {{ scope.row.range.selfPaid.startText }} - {{ scope.row.range.selfPaid.endText }}
              .detail-item.flex.column
                .row.flex.align-center.justify-space-between
                  span 專案收入
                  span NT $ {{ $toCurrencyNumber(scope.row.project_paid_appointment_amount) }}
                .row(v-if="scope.row.range?.projectPaid")
                  .text-clickable(@click="goAccountingPage(scope.row, 'projectPaid', scope.row.range.projectPaid)"
                    ) {{ scope.row.range.projectPaid.startText }} - {{ scope.row.range.projectPaid.endText }}
              .detail-item.flex.column
                .row.flex.align-center.justify-space-between
                  span 活動收入
                  span NT $ {{ $toCurrencyNumber(scope.row.activity_amount) }}
                .row(v-if="scope.row.range?.activity")
                  .text-clickable(@click="goAccountingPage(scope.row, 'activity', scope.row.range.activity)"
                    ) {{ scope.row.range.activity.startText }} - {{ scope.row.range.activity.endText }}
              .detail-total.text-align-right.blod 小計 NT$ {{ scope.row.work_amount }}
            .other.part.flex.column
              .title.flex.align-center.justify-space-between.gap-10
                span 其他項目
                .items.flex.align-center.gap-8
                  .add-item.clickable(@click="addExtraItem(scope.row, true)") + 加項
                  .minus-item.clickable(@click="addExtraItem(scope.row, false)") - 減項
              template(v-for="(extra, idx) in scope.row.extra_items")
                .other-item.flex.align-center.gap-10(
                  :class="{'positive': extra.positive, 'negative': !extra.positive}"
                  :key="extra.id")
                  .sign
                    template(v-if="extra.positive") +
                    template(v-else) -
                  .title.flex-1
                    template(v-if="extra.editing")
                      el-input(size="mini", v-model="editName")
                    template(v-else)
                      span {{ extra.name }}
                  .amount
                    template(v-if="extra.editing")
                      el-input(size="mini", v-model="editAmount", type="number")
                    template(v-else)
                      span NT$ {{ $toCurrencyNumber(extra.amount) }}
                  .buttons.flex.align-center
                    template(v-if="extra.editing")
                      el-button(type="success" icon="el-icon-check" circle size="mini" plain @click="saveExtra(scope.row, extra)")
                      el-button(type="danger" icon="el-icon-close" circle size="mini" plain, @click="cancelExtra(scope.row, extra, idx)")
                    template(v-else)
                      //- el-button(type="primary" icon="el-icon-edit" circle size="mini" plain :disabled="editing", @click="startEdit(extra)")
                      el-button(type="danger" icon="el-icon-delete" circle size="mini" plain :disabled="editing", @click="deleteExtra(scope.row, idx)")
              .other-total.text-align-right.bold 小計 NT${{ scope.row.extra_payment + scope.row.professional_owed_payment }}
            .total.part.flex.column.gap-10(:class="{'error': scope.row.confirm_status === 'wrong_amount'}")
              .title 實發金額
              .amount.font-size-24.bold NT$ {{ $toCurrencyNumber(scope.row.amount) }}
              .error-text(v-if="scope.row.confirm_status === 'wrong_amount'") 金額錯誤，請確認明細後重新確認
              template(v-if="scope.row.confirm_status === 'not_confirmed'")
                el-button(type="primary" disabled) 已通知，尚未確認
              template(v-else-if="scope.row.confirm_status === 'confirmed'")
                el-button(type="primary" disabled) 確認無誤
              template(v-else)
                el-button(type="primary", @click="notify(scope.row)") 薪資確認通知
      el-table-column(label="專業人士", width="120")
        template(slot-scope="scope")
          span {{ scope.row.professional.family_name }}{{ scope.row.professional.given_name }}
      el-table-column(label="結帳收入")
        template(slot-scope="scope")
          span {{ $toCurrencyNumber(scope.row.work_amount) }}
      el-table-column(label="加項總額")
        template(slot-scope="scope")
          span {{ $toCurrencyNumber(scope.row.extra_payment) }}
      el-table-column(label="減項總額")
        template(slot-scope="scope")
          span {{ $toCurrencyNumber(scope.row.professional_owed_payment) }}
      el-table-column(label="實發金額")
        template(slot-scope="scope")
          span {{ $toCurrencyNumber(scope.row.amount) }}
      el-table-column(label="薪資總結確認", width="160")
        template(slot-scope="scope")
          span(v-if="scope.row.confirm_status === 'not_notified'") 尚未通知
          span(v-if="scope.row.confirm_status === 'not_confirmed'") 已通知 （尚未確認）
          span(v-if="scope.row.confirm_status === 'confirmed'") 已確認
          span(v-if="scope.row.confirm_status === 'wrong_amount'") 金額錯誤
  .pagination.flex.align-center.justify-end
    el-pagination(
      layout="total, sizes, prev, pager, next, ->, jumper"
      :page-size.sync="limit"
      :current-page.sync="page"
      :total="total"
      :page-sizes="[50, 100, 200]"
    )
</template>

<script>
import {
  getPaySlips,
  getPaySlipDetail,
  addPayslipItem,
  deletePayslipItem,
  notifyPayslips,
} from '@/api/accounting';

function timestampToRangeMonth(ts) {
  const d = new Date(ts * 1000);
  return `${d.getFullYear()} 年 ${d.getMonth() + 1} 月`;
}

export default {
  data() {
    const time = new Date();
    time.setDate(1);
    time.setHours(0, 0, 0, 0);
    const year = time.getFullYear();
    const month = time.getMonth() + 1;
    return {
      time,
      year,
      month,
      page: 1,
      limit: 100,
      total: 0,

      allChecked: false,
      payslips: [],
      editName: '',
      editAmount: 0,
      editing: false,
    };
  },
  computed: {
    canBatchSendNotify() {
      return this.payslips.reduce((v, p) => v || p.checked, false);
    },
  },
  watch: {
    limit() {
      this.page = 1;
      this.loadData();
    },
    page() {
      this.loadData();
    },
    allChecked(val) {
      console.log(val);
      this.payslips = this.payslips.map((p) => ({
        ...p,
        checked: p.canNotify && val,
      }));
      this.$forceUpdate();
    },
  },
  methods: {
    goAccountingPage(payslip, target, range) {
      console.log(payslip, target);
      const url = `/#/revenue/account-statement?professional=${payslip.professional.id}&type=${target}&year=${this.year}&month=${this.month}&start=${range.start}&end=${range.end}`;
      window.open(url);
    },
    expandRow(payslip) {
      if (payslip.range) {
        return;
      }
      this.$execWithLoading(async () => {
        const rsp = await getPaySlipDetail(payslip.id);
        const range = {};
        if (rsp.self_paid_appointments.length > 0) {
          range.selfPaid = {
            start: rsp.self_paid_appointments[0].start_at - 1,
            end: rsp.self_paid_appointments[1].end_at + 1,
            startText: timestampToRangeMonth(rsp.self_paid_appointments[0].start_at),
            endText: timestampToRangeMonth(rsp.self_paid_appointments[1].start_at),
          };
        }
        if (rsp.project_paid_appointments.length > 0) {
          range.projectPaid = {
            start: rsp.project_paid_appointments[0].start_at - 1,
            end: rsp.project_paid_appointments[1].end_at + 1,
            startText: timestampToRangeMonth(rsp.project_paid_appointments[0].start_at),
            endText: timestampToRangeMonth(rsp.project_paid_appointments[1].start_at),
          };
        }
        if (rsp.activities.length > 0) {
          range.activity = {
            start: rsp.activities[0].start_at - 1,
            end: rsp.activities[1].end_at + 1,
            startText: timestampToRangeMonth(rsp.activities[0].start_at),
            endText: timestampToRangeMonth(rsp.activities[1].start_at),
          };
        }
        // eslint-disable-next-line no-param-reassign
        payslip.range = range;
        setTimeout(() => {
          this.$forceUpdate();
        }, 100);
      }, (e) => {
        this.$showAxiosException('載入細節資料失敗', e);
      });
    },
    async notify(payslip) {
      return this.$execWithLoading(async () => {
        await notifyPayslips([payslip.id]);
        // eslint-disable-next-line no-param-reassign
        payslip.confirm_status = 'not_confirmed';
        this.$showSuccess('薪資確認通知已送出');
      }, (e) => {
        this.$showAxiosException('薪資確認通知失敗', e);
      });
    },
    async sendBatchNotify() {
      const payslips = this.payslips.filter((p) => p.checked).map((p) => p.id);
      return this.$execWithLoading(async () => {
        await notifyPayslips(payslips);
        this.$showSuccess('薪資確認通知已送出');
        this.allChecked = false;
        this.loadData();
      }, (e) => {
        this.$showAxiosException('薪資確認通知失敗', e);
      });
    },
    async saveExtra(payslip, extra) {
      const amount = parseInt(this.editAmount, 10);
      let sendAmount = amount;
      if (!extra.positive) {
        sendAmount *= -1;
      }
      this.editing = false;
      return this.$execWithLoading(async () => {
        const rsp = await addPayslipItem(payslip.id, this.editName, sendAmount);
        // eslint-disable-next-line no-param-reassign
        extra.name = this.editName;
        // eslint-disable-next-line no-param-reassign
        extra.amount = amount;
        // eslint-disable-next-line no-param-reassign
        extra.id = rsp.id;
        // eslint-disable-next-line no-param-reassign
        extra.editing = false;

        // update amount
        if (sendAmount >= 0) {
        // eslint-disable-next-line no-param-reassign
          payslip.extra_payment += sendAmount;
        } else {
        // eslint-disable-next-line no-param-reassign
          payslip.professional_owed_payment += sendAmount;
        }
        // eslint-disable-next-line no-param-reassign
        payslip.amount += sendAmount;

        // eslint-disable-next-line no-param-reassign
        payslip.confirm_status = 'not_notified';
        this.$forceUpdate();
        this.$showSuccess('新增項目成功');
      }, (e) => {
        this.$showAxiosException('新增項目失敗', e);
      });
    },
    startEdit(extra) {
      this.editing = true;
      this.editAmount = extra.amount;
      this.editName = extra.name;
      // eslint-disable-next-line no-param-reassign
      extra.editing = true;
    },
    cancelExtra(payslip, extra, idx) {
      this.editing = false;
      if (extra.id !== undefined) {
      // eslint-disable-next-line no-param-reassign
        extra.editing = false;
        return;
      }
      payslip.extra_items.splice(idx, 1);
      this.$forceUpdate();
    },
    async deleteExtra(payslip, idx) {
      return this.doModifyCheck(payslip).then(() => this.$execWithLoading(async () => {
        await deletePayslipItem(payslip.id, payslip.extra_items[idx].id);
        const removeItem = payslip.extra_items[idx];
        payslip.extra_items.splice(idx, 1);

        console.log(removeItem);
        if (removeItem.positive) {
        // eslint-disable-next-line no-param-reassign
          payslip.extra_payment -= removeItem.amount;
          // eslint-disable-next-line no-param-reassign
          payslip.amount -= removeItem.amount;
        } else {
        // eslint-disable-next-line no-param-reassign
          payslip.professional_owed_payment += removeItem.amount;
          // eslint-disable-next-line no-param-reassign
          payslip.amount += removeItem.amount;
        }

        // eslint-disable-next-line no-param-reassign
        payslip.confirm_status = 'not_notified';
        this.$forceUpdate();
        this.$showSuccess('刪除項目成功');
      }, (e) => {
        this.$showAxiosException('刪除項目失敗', e);
      }));
    },
    doModifyCheck(payslip) {
      if (payslip.confirm_status !== 'not_notified') {
        return this.$confirm('已進入薪資的二次確認流程，若調整加減項後需重新進行。', '修改確認', {
          confirmButtonText: '進行修改',
          cancelButtonText: '取消',
          type: 'warning',
        });
      }
      return new Promise((r) => { r(); });
    },
    addExtraItem(payslip, isAdd) {
      this.doModifyCheck(payslip).then(() => {
        this.addSingleExtraItem(payslip, isAdd);
      });
    },
    addSingleExtraItem(payslip, isAdd) {
      this.editing = true;
      this.editAmount = 0;
      this.editName = '';
      payslip.extra_items.push({
        editing: true,
        name: '',
        amount: 0,
        positive: isAdd,
      });
    },
    async loadData() {
      return this.$execWithLoading(async () => {
        const rsp = await getPaySlips(this.year, this.month, this.page - 1, this.limit);
        console.log(rsp);
        this.total = rsp.meta.total;
        this.payslips = rsp.payslips.map((p) => ({
          ...p,
          range: undefined,
          extra_items: p.extra_items.map((item) => ({
            id: item.id,
            name: item.name,
            amount: Math.abs(item.amount),
            positive: item.amount >= 0,
            editing: false,
          })),
          canNotify: p.confirm_status === 'not_notified' || p.confirm_status === 'wrong_amount',
        }));
      }, (e) => {
        this.$showAxiosException('載入薪資明細失敗', e);
      });
    },
    prevMonth() {
      this.time.setMonth(this.time.getMonth() - 1);
      this.getYearMonth();
      this.loadData();
    },
    nextMonth() {
      this.time.setMonth(this.time.getMonth() + 1);
      this.getYearMonth();
      this.loadData();
    },
    getYearMonth() {
      this.year = this.time.getFullYear();
      this.month = this.time.getMonth() + 1;
    },
    getFilter() {
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.expand-content {
  .part {
    border: 1px solid #C1CBDB;
    border-radius: 8px;
    padding: 10px;
    gap: 10px;
  }
  .detail {
    flex: 0 0 320px;
    .detail-item {
      justify-content: space-between;
      background: #F5F7FA;
      padding: 10px;
      border-radius: 6px;
    }
  }
  .other {
    flex: 0 0 350px;
    .title {
      .add-item {
        background: #E1F3D8;
        border: 1px solid #DCDFE6;
        border-radius: 12px;
        height: 24px;
        padding: 2px 12px 2px 12px;
      }
      .minus-item {
        background: #FEF0F0;
        border: 1px solid #DCDFE6;
        border-radius: 12px;
        height: 24px;
        padding: 2px 12px 2px 12px;
      }
    }
    .other-item {
      padding: 10px;
      border-radius: 8px;
      gap: 4px;
      &.positive {
        background: #E1F3D8;
      }
      &.negative {
        background: #FEF0F0;
      }
      .amount {
        .el-input {
          width: 80px;
        }
      }
    }
  }
  .total {
    flex: 0 0 220px;
    background: #E6F4F4;
    &.error {
      background: #FEF0F0;
      .error-text {
        font-size: 12px;
        color: #F56C6C;
      }
    }
  }
}
</style>

<style lang="scss">
.payslip-page {
  .content {
    overflow: hidden;
    .el-table {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .el-table__header-wrapper {
        flex: 0 0 48px;
      }
      .el-table__body-wrapper {
        overflow-y: scroll !important;
        overflow-y: auto;
      }
    }
  }
}
</style>
