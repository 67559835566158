<template lang="pug">
.users-page.flex.column.gap-20
  .search-bar.flex.align-center.justify-space-between
    .filter.flex.align-end.gap-10
      .column.flex.column.align-start.gap-4
        .title 關鍵字搜尋
        el-input.long-input(
          @change="loadData"
          v-model="keyword", placeholder="可輸入個案姓名、個案編碼、身分證、護照、聯絡電話、緊急聯絡人或緊急聯絡電話")
    .buttons.flex.align-center.gap-4
      el-button(type="primary", icon="el-icon-plus", @click="addUser") 新增個案
      el-button(
        type="primary", icon="el-icon-plus"
        @click="$refs.appointmentNew.$emit('show')"
        id="add-reserve-btn"
        ) 新增預約
  .table.hack-table
    el-table(:data="users")
      el-table-column(label="個案姓名")
        template(slot-scope="scope")
          .flex.justify-space-between.align-center
            .name.clickable.link-text(@click="viewUser(scope.row)")
              span {{scope.row.family_name}}{{scope.row.given_name}}
            .marks.flex.align-center.gap-4
              i.el-icon-message.warn-color(v-if="!scope.row.verified")
              i.el-icon-warning.danger-color(v-if="!scope.row.information_completed")
      el-table-column(label="個案編號" prop="id")
      el-table-column(label="聯絡電話")
        template(slot-scope="scope")
          span {{ $getShowPhone(scope.row.phone) }}
      el-table-column(label="專業人士", width="120")
          template(slot-scope="scope")
            pro-badge(
              v-if="scope.row.professional !== undefined", :professional="scope.row.professional")
      el-table-column(label="緊急聯絡人")
        template(slot-scope="scope")
          .contact.flex.align-center.gap-6(
            v-if="scope.row.emergency_person && scope.row.emergency_person !== ''")
            .name {{scope.row.emergency_person}}
            .relation ({{scope.row.emergency_relationship}})
      el-table-column(label="緊急聯絡電話")
        template(slot-scope="scope")
          span {{ $getShowPhone(scope.row.emergency_contact) }}
  .pagination.flex.align-center.justify-end
    el-pagination(
      layout="total, sizes, prev, pager, next, ->, jumper"
      :page-size.sync="limit"
      :current-page.sync="currentPage"
      :total="total"
      :page-sizes="[50, 100, 200]"
    )
  person-editor(ref="personEditor", :append-to-body="true", @finish="finishNew")
  appointment-new(
    ref="appointmentNew"
  )
</template>

<script>
import { getUsers } from '@/api/user';
import PersonEditor from '@/components/drawers/PersonEdit.vue';
import AppointmentNew from '@/components/drawers/AppointmentNew.vue';

export default {
  components: {
    'person-editor': PersonEditor,
    'appointment-new': AppointmentNew,
  },
  data() {
    return {
      keyword: '',
      users: [],
      limit: 100,
      currentPage: 1,
      total: 0,
      detailUser: undefined,
    };
  },
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      this.$execWithLoading(async () => {
        const keyword = this.keyword !== '' ? this.keyword : undefined;
        const rsp = await getUsers(this.currentPage - 1, this.limit, keyword);
        this.users = rsp.data;
        this.total = rsp.meta.total;
      });
    },
    viewUser(user) {
      this.$root.$emit('show-user', user);
    },
    addUser() {
      this.$refs.personEditor.$emit('show');
    },
    finishNew() {
      this.loadData();
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadData();
    }, 10);
  },
};
</script>

<style lang="scss" scoped>
.users-page {
  padding: 30px 0;
  position: relative;
  .search-bar {
    padding: 0 30px;
    .filter {
      .column {
        .title {
          font-size: 12px;
        }
      }
    }
  }
  .table {
    flex: 1;
    padding: 0 30px;
  }
  .pagination {
    flex: 0 0 auto;
  }
  .empty {
    padding: 0 30px;
    margin-top: 20px;
  }
  .user-viewer {
    position: absolute;
    opacity: 0.01;
    transition: all .5s ease-in-out;
    pointer-events: none;
    top: 0;
    left: 0;
    z-index: 1;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }
  .long-input {
    width: 560px;
  }
}
</style>
